.carousel-background {
    background-color: #F9F9EA; 
    width: 100%;
    margin: auto;
}

// .ant-carousel .slick-slide img {
//     display: flex;
//     height: auto;
//     justify-content: center;
//     margin: auto;
//     width: 80%;   
// }
.carousel-home {
    .slick-slide {
        .carousel-background {
            img {
                width: 80% !important;
                display: flex !important;
                height: auto !important;
                justify-content: center !important;
                margin: auto !important;
            }
        }
    }
   
}

.carousel-2items {
    .slick-slide {
        .carousel-background {
            img {

                width: 120% !important;
                display: flex !important;
                padding: 0rem 1rem !important;
                height: auto !important;
                justify-content: space-between !important;
                margin: auto !important;
            }
        }
    }
   
}

.ant-carousel{
    position: relative;
}

.carousel-media {
    padding-bottom: 10rem;
    .slick-slide {
        .carousel-background {
            img {
                width: 100% !important;
                padding: 20px !important;
                display: flex !important;
                height: auto !important;
                justify-content: center !important;
                margin: auto !important;
            }
        }
    }
   
}

.ant-carousel .slick-dots li {
    display: none !important;
}
.ant-carousel .slick-dots {
    display: none !important;
}
.ant-carousel .slick-dots li.slick-active {
    display: none !important;
}

.carousel-mobile {
    .carousel-mobile-img {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .carousel-background {
        position: relative;
        background-color: #F9F9EA; 
    }
}

@media only screen and (max-width: 440px) {
    .carousel-home {
        display: none!important;
    }
    .carousel-mobile {
        .carousel-mobile-img {
        margin: auto;
        width: 90%;
        height: 100%;
        display: flex;
        padding: 0px 0px;
        // background-color: red;
    }
    }
}   