.media-center-image {
    width: 12rem;
    height:8rem;
    object-fit: cover;
    margin-right: 5px;
}
.media-center-image:hover {
    transform: scale(1.2);
    transition-duration: 0.6s;
}
.spacecing {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.news-spacecing {
    padding-top: 70px;
    padding-bottom: 4rem;
    margin-top: -50px;
}
.media-slider-image {
    margin: auto; 
    width: auto;
    height: 40.833333333333332vw;
}

.media-slider-container {
    margin-top: 5rem; 
    margin-right: 1.5vw;
}
.media-box-data{
    margin: auto;
    width: 100%;
    height: 100%;
}
.media-news-container {
    background-color: #fdf7e9;
}
.media-news-title {
    font-family: 'Source Serif Pro', serif;
    color: #414042;
    text-align: center;
    font-size: 24pt;
    font-weight: bold;
    text-transform: uppercase;
}

.insurance-content-mobile{
    margin-bottom: 0px;
}

.sub-title:hover {
    color: #C69703;
}

@media only screen and (max-width: 767px) {
    .media-center-image {
        width: 12rem;
        height:8rem;
        object-fit: cover;
    }
    
    .media-slider-image {
        margin: auto; 
        width: auto;
        height: 40.833333333333332vw;
    }
    
    .media-slider-container {
        margin-top: 5rem; 
        margin-right: 1.5vw;
    }
    .media-box-data{
        width: 60%;
        height: 60%;
        margin: auto;
    }
    .sub-title {
        @include textClamp(2, 70%);
        text-align: center;
        font-size: 12pt;
        margin-top: -35px;
        margin-bottom: 1rem;
        font-family: 'Source Serif Pro',serif;
        font-weight: bold;
        
    }
    .media-news-title {
        font-family: 'Source Serif Pro', serif;
        color: #414042;
        text-align: center;
        font-size: 20pt;
        font-weight: bold;
        text-transform: uppercase;
    }
}

@media only screen and (max-width: 500px) {
    .spacecing {
        padding-top: 4rem;
        padding-bottom: 2rem;   
    }

    .media-news-title {
        font-family: 'Source Serif Pro', serif;
        color: #414042;
        text-align: center;
        font-size: 16pt;
        font-weight: bold;
        text-transform: uppercase;
    }
}

@media only screen and (max-width: 375px) {
    .media-center-image {
        width: 8rem;
        height:6rem;
        margin-right: 5px;
    }
    
    .media-slider-image {
        margin: auto; 
        width: auto;
        height: 40.833333333333332vw;
    }
    
    .media-slider-container {
        margin-top: 5rem; 
        margin-left: 2rem;
    }

    .media-box-data{
        width: 40%;
        height: 40%;
        margin: auto;
    }

    .sub-title {
        @include textClamp(2, 70%);
        text-align: center;
        font-size: 10pt;
        margin-top: -35px;
        margin-bottom: 1rem;
        font-family: 'Source Serif Pro',serif;
        font-weight: bold;
        
    }

    .media-news-title {
        font-family: 'Source Serif Pro', serif;
        color: #414042;
        text-align: center;
        font-size: 16pt;
        font-weight: bold;
        text-transform: uppercase;
    }

}