.brief-intro-image {
    // background-image: url("../components/BriefIntro/assets/06.png");
    // background-repeat: no-repeat;
    // background-size: contain;
    font-family: "Source Serif Pro", serif;
    z-index: -1;

}
.para-header {
    font-weight: bold;
}
.brief-intro-row {
    margin: 5rem 0 0 0;
    font-weight: normal;
}

.brief-video-size {
    height: 85%;
    width: 85%;
}

.brief-intro-container {
    padding-bottom: 2rem;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  }
  
  .react-player {
    position: relative;
    top: 0;
    left: 0;
    margin-right: 5rem;
  }
  .brief-intro-col {
    padding-bottom: 0;
}
@media only screen and (max-width: 1000px) {
    .react-player {
        position: relative;
        top: 0;
        left: 0;
        margin: auto;
      }

      .brief-intro-container {
        padding-bottom: 0rem;
    }
}

@media only screen and (max-width: 767px) {
    // .brief-intro-image {
    //     background-image: url("../components/BriefIntro/assets/06.png");
    //     background-repeat: no-repeat;
    //     background-size: contain;
    // }
    
    .brief-intro-row {
        margin: 0 0 0 0;
    }

    .brief-intro-col {
        padding-bottom: 0;
    }
    .react-player {
        position: relative;
        top: 0;
        left: 0;
        margin: auto;
    }
    .brief-intro-col {
        padding-bottom: 1rem;
    }
}

@media only screen and (max-width: 375px) {
    // .brief-intro-image {
    //     background-image: url("../components/BriefIntro/assets/06.png");
    //     background-repeat: no-repeat;
    //     background-size: contain;
    // }
    
    .brief-intro-row {
        margin: 0 0 0 0;
    }
    .brief-intro-col {
        padding-bottom: 5rem;
    }
    .react-player {
        position: relative;
        top: 0;
        left: 0;
        margin: auto;
        }

        .brief-intro-container {
            padding-bottom: 0rem;
        }   
}
@media only screen and (min-width: 1440px) {
    .para-header {
        text-align: center;
        padding-top: 5%;
        font-size: 30pt;
    }
    .para {
        font-size: 18pt;
    }
}