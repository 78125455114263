.section2-background {
    background-image: url("../components/Section2/assets/section2img.png");
    background-size: 100%;
    background-repeat: no-repeat;
}
@media only screen and (max-width: 2560px) {
    .section2-background {
        background-size: 107%;
}
}
@media only screen and (max-width: 1921px) {
    .section2-background {
        background-size: 133%;
    }
}
@media only screen and (max-width: 1441px) {
    .section2-background {
        background-size: 169%;
    }
}