.media-data-img {
    width: 29rem;
    object-fit: contain;
    height: 18rem;
    margin: auto;
}
.media-data-img:hover {
    transform: scale(1.05);
    transition-duration: 0.6s;
}

    .slick-arrow .slick-prev {
        font-size: 20px;
        text-align: center;
        
    }
  .carousel-media .slick-prev::before {
    display: block;
    position: absolute;
    left: -40px;
    bottom: 50px;
    /* width: 100px; */
    font-size: 45px;
    color: #C69703;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50%;
    width: 45px;
    height: 45px;
     
  }
  .slick-arrow .slick-next {
    font-size: 20px;
    text-align: center;
  }
  .carousel-media .slick-next::before {
    display: block;
    position: absolute;
    right: -40px;
    bottom: 50px;
    /* width: 100px; */
    font-size: 45px;
    color: #C69703;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }
.media-data-title {
    
    text-align: center;
    font-family: 'Source Serif Pro',serif;
    font-weight: bold;
    white-space: pre-line;
    font-size: 20pt;
    display: flex;             
    min-height: 7vh;
    align-items: center;
    justify-content: center;
    @include textClamp(2);
}
.media-data-title:hover {
    color: #C69703;
}
.media-data-btn-container {
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top: 2rem
}
@media only screen and (max-width: 1100px) {
    .carousel-media .slick-next::before {
        display: none
    }

    .carousel-media .slick-prev::before {
        display: none
    }
} 

@media only screen and (max-width: 767px)   {
    .media-data-img{
        width: 24rem;
        height: 13.5rem;
        margin: auto;
    }
    .media-data-title {

        text-align: center;
        font-family: 'Source Serif Pro',serif;
        font-weight: bold;
        white-space: pre-line;
        font-size: 18pt;
        display: flex;             
        min-height: 7vh;
        align-items: center;
        justify-content: center;
        @include textClamp(2);
    }
    
}

@media only screen and (max-width: 375px)   {
    .media-data-img {
        width: 16rem;
        height: 9rem;
        margin: auto;
    }
    .media-data-title {
        text-align: center;
        font-family: 'Source Serif Pro',serif;
        font-weight: bold;
        white-space: pre-line;
        font-size: 16pt;
        display: flex;             
        min-height: 7vh;
        align-items: center;
        justify-content: center;
        @include textClamp(2);
    }
}  