// width, height
@mixin size($width: 100px, $height: $width) {
  width: $width;
  height: $height;
}
// absoluteCenter
@mixin absoluteCenter($direction: 'left', $position: absolute) {
  position: $position;
  // @if $variable == {}
  @if $direction == 'left' {
    left: 50%;
    transform: translateX(-50%);
  }
  @if $direction == 'top' {
    top: 50%;
    transform: translateY(-50%);
  }
  @if $direction == 'both' {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
// backgroundcolor
@mixin bg($color: red) {
  background-color: $color;
}
// absolute full
@mixin absoluteFull() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
// mixin maxWidth($breakpoint){@content}
@mixin maxWidth($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}
// mixin minWidth($breakpoint)
@mixin minWidth($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}
// Flexbox
@mixin flexbox($align-items: stretch, $justify-content: flex-start, $direction: row, $flex: flex, $wrap: nowrap) {
  display: $flex;
  align-items: $align-items;
  justify-content: $justify-content;
  flex-direction: $direction;
  flex-wrap: $wrap;
}
// hidescroll
@mixin hideScroll() {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
// text clamp
@mixin textClamp($rows: 1, $size: 100%) {
  display: -webkit-box;
  -webkit-line-clamp: $rows;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: $size;
  max-width: $size;
  white-space: pre-wrap;
  word-break: break-word;
  margin: auto;
}

@mixin hoverEffect($itemClass, $contentClass, $transform: 'true') {
  #{$itemClass} {
    position: relative;
    z-index: 1;
    & > * {
      transition: transform 0.25s linear;
      will-change: transform;
    }
    #{$contentClass} {
      @include absoluteFull();
      @include flexbox(center, center, column);
      opacity: 0;
      visibility: hidden;
      transition: all 0.25s linear;
      background: linear-gradient(to bottom, rgba($primary-color, 0.8), rgba($secondary-color, 0.8));
      z-index: 2;
    }
    @if $transform == 'true' {
      &:after {
        content: '';
        @include absoluteFull();
        @include bg($third-color);
        // transition: transform 0.25s linear;
        // will-change: transform;
        z-index: -1;
      }
    }
    &:hover {
      #{$contentClass} {
        opacity: 1;
        visibility: visible;
      }
      @if $transform == 'true' {
        & > * {
          transform: translate(-1rem, -1rem);
        }
        // transform: translate(-1rem, -1rem);
        // &:after {
        //   transform: translate(1rem, 1rem);
        // }
      }
    }
  }
}

@mixin touchSlider($width: 80%, $gap: 2.5rem, $padding: 1rem) {
  display: grid !important;
  justify-content: flex-start !important;
  grid-auto-columns: $width;
  grid-auto-flow: column;
  grid-template-columns: unset;
  grid-column-gap: $gap;
  overflow-x: auto;
  padding: $padding;
  scroll-snap-type: x mandatory;
  scroll-snap-stop: always;
  @include hideScroll;
  & > * {
    scroll-snap-align: center;
    width: auto;
  }
}
@mixin color($config: ()) {
  // ()

  // Extending the default arguments with the given object
  $config: extend(
    (
      color: red,
    ),
    $config
  );

  color: map-get($config, color);
}

// Flexbox
@mixin flexbox2($config: ()) {
  $config: extend(
    (
      align-items: stretch,
      justify-content: flex-start,
      flex-direction: row,
      display: flex,
      flex-wrap: nowrap,
    ),
    $config
  );

  display: map-get($config, display);
  align-items: map-get($config, align-items);
  justify-content: map-get($config, justify-content);
  flex-direction: map-get($config, flex-direction);
  flex-wrap: map-get($config, flex-wrap);
}
