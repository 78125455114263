.gallery_container
{
    width: 100%;
    justify-items: center;
    margin: 30px 0;
    position: relative;

}
.gallery_backround
{
    align-self: center;
    width: 76%;
    margin: auto;
}
.gallery_list
{
    position: absolute;
    width: 62.5%;
    top: 30%;
    border-top: 10px solid #fff;
    padding: 10px 0px;
    border-bottom: 10px solid #fff;
    left: 19%;
}
.gallery_media_button_container
{
    position: absolute;
    top: 80%;
    width: 100%; 
    height : 10%;

    display:flex;
    justify-content:center;
    align-items:center;
    }
.gallery_item
{
    display: inline-block;
    width: 20%;
    padding: 0px 9px;
}
.ant-image-preview-img
{
    width: 598px;
    height: 433px;
    margin: auto;
    margin-top: -16%;
    border-radius: 14px;
}
@media (max-width: 1079px) and (min-width: 375px)
{   
    .gallery_container
    {
        padding-bottom: 130px;
    }
    .gallery_list
    {
        position: absolute;
        width: 62.5%;
        top: 30%;
        border: none;
        padding: 6px 0px;
        left: 19%;
        margin: auto;
        
    }

    .gallery_item   
    {
        display: inline-block;
        width: 20%;
        padding: 0px 5px;
    }
    .ant-image-preview-img
    {
        margin-top: -28%
    }
}  
@media (max-width: 820px) and (min-width: 376px)
{   
    .gallery_container
    {
        background-image: url('../components/Gallery Home/assets/Asset\ 1.svg');
        padding-bottom: 130px;
        background-size: 80%;
        background-repeat: no-repeat;
        margin-top: 0;
    }
    .gallery_backround
    {
        display: none;
    }
    .gallery_media_button_container{
        top: 85%;
        position: absolute;
        width: 100%; 
        height : 10%;
    
        display:flex;
        justify-content:center;
        align-items:center;
    }
    .gallery_list
    {
        width: 100%;
        position: relative;
        margin: auto;
        left: 0;
        justify-content: center;
        text-align: center;
    }
    .gallery_item
    {
        width: 40%;
        padding: 3px 6px;
        border-radius: 4px;
    }
    .gallery_item:first-child
    {
        display: block;
        margin: auto;
    }
    .ant-image-preview-img
    {
        margin-top: -20%;
        width: 334px;
        height: 242px;
    }
}
@media (max-width: 375px)
{
    .gallery_container
    {
        padding-bottom: 60px !important;
        padding-top: 30px;
        background-image: url('../components/Gallery Home/assets/Asset\ 1.svg');
        background-size: 80%;
        background-repeat: no-repeat;
    }
    .gallery_backround
    {
        display: none;
    }
    .gallery_media_button_container{
        top: 85%;
        position: absolute;
        width: 100%; 
        height : 10%;
    
        display:flex;
        justify-content:center;
        align-items:center;
    }
    .gallery_list
    {
        width: 100%;
        position: relative;
        margin: auto;
        left: 0;
        justify-content: center;
        text-align: center;
        border: none;

    }
    .gallery_item
    {
        width: 40%;
        padding: 3px 6px;
        border-radius: 4px;
    }
    .gallery_item:first-child
    {
        display: block;
        margin: auto;
    }
    .ant-image-preview-img
    {
        margin-top: -36%;
        width: 334px;
        height: 242px;
    }
}
@media (min-width: 821px) and (max-width: 1080px)
{
    .gallery_list
    {
        position: absolute;
        width: 62.5%;
        top: 24%;
        border-top: 6px solid #fff;
        padding: 6px 0px;
        border-bottom: 6px solid #fff;
        left: 19%;
    }
}