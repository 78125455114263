.para {
    text-align: justify;
    margin-left: 10%;
    margin-right: 10%;
    font-family: 'Source Serif Pro',serif;
    margin-bottom: 5%; 
    font-size: 21pt;
    line-height: 1.5;
}
.para-bold {
    font-weight: bold;
}
.para-item {
    text-align: justify;
    margin-left: 10%;
    margin-right: 10%;
    font-family: 'Source Serif Pro',serif;
    font-size: 21pt;
    line-height: 1.5;
}

.para-header {
    text-align: center;
    padding-top: 5%;
    font-size: 36pt;
}
.bio-container {
    background-color: #f7f7f7;
    margin: 0 10% 0 10%;
    min-height: 10rem;
    width: auto;
    font-family: 'Source Serif Pro', serif;
}

@media only screen and (max-width: 767px) {
    .para {
        font-size: 11pt;
        line-height: 1.5;
        margin-bottom: 3%;
        margin-left: 3%;
        margin-right: 3%;
        text-align: justify;
    }
    
    .para-header {
        font-size: 14pt;
        padding-top: 3%;
        justify-content: center;
        margin: auto;
        width: 73%;
    }

    .bio-container {
        background-color: #f7f7f7;
        margin: 0 5% 0 5%;
        min-height: 10rem;
        width: auto;
    }
}

@media only screen and (max-width: 375px) {
    .para {
        text-align: justify;
        margin-left: 3%;
        margin-right: 3%;
        margin-bottom: 3%; 
        font-size: 12pt;
        line-height: 1.5;
    }
    
    .para-header {
        text-align: center;
        padding-top: 3%;
        font-size: 14pt;
    }

    .bio-container {
        background-color: #f7f7f7;
        margin: 0 5% 0 5%;
        min-height: 10rem;
        width: auto;
    }
}
