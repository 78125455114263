@import 'styles/mixins';
.header-contribution-image {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  position: relative; 
}

.link-click{
  font-weight: bold;
  font-family: 'Source Serif Pro',serif;
  color: black;
}
.link-click:hover{ 
  color: #c69703;
}

.containerdetail-text{
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 79rem; 
  height: 35rem;
}

.title-details {
  width: 100%;
  padding : 20px;
  font-size: 3.1rem;
  font-weight: bold;
  font-family: 'Source Serif Pro',serif;
  text-align: center;
}

.not-change{
  width: auto;
  height: 54rem;
}

.contribution-item-title-0 {
  position: absolute;
  top: 1%;
  left: 44%;
  cursor: pointer;
  font-family: 'Source Serif Pro',serif;
  font-weight: bold;
  font-size : 1.8rem;
  &-hidden {
    display: none;
  }
}
.box-underheading {
  background-color: #c69703;
  height: 38px;
  width: 238px;
  margin: auto;
}

.contribution-item-title-1 {
  position: absolute;
  top: 14%;
  left: 20%;
  font-weight: bold;
  cursor: pointer;
  font-family: 'Source Serif Pro',serif;
  font-size : 1.8rem;

  &-hidden {
    display: none;
  }
}
.contribution-item-title-2 {
  position: absolute;
  top: 14%;
  right: 20%;
  cursor: pointer;
  font-size : 1.8rem;
  font-weight: bold;
  font-family: 'Source Serif Pro',serif;
  &-hidden {
    display: none;
  }
}
.contribution-item-title-3 {
  position: absolute;
  top: 46%;
  right: 1%;
  cursor: pointer;
  font-size : 1.8rem;
  font-weight: bold;
  font-family: 'Source Serif Pro',serif;
  &-hidden {
    display: none;
  }
}
.contribution-item-title-4 {
  position: absolute;
  top: 44%;
  left: 1%;
  cursor: pointer;
  font-size : 1.8rem;
  font-weight: bold;
  font-family: 'Source Serif Pro',serif;
  &-hidden {
    display: none;
  }
}
.insurance-content {
  margin: auto auto 10rem auto;
  width: 60%;
  line-height: 1.5;
  text-align: justify;
  font-family: 'Source Serif Pro',serif;
  font-size: 1.5rem;
}
.startup-content {
  margin: auto auto 10rem auto;
  width: 60%;
  line-height: 1.5;
  text-align: justify;
  font-family: 'Source Serif Pro',serif;
  font-size: 1.5rem;
}
.foreign-content {
  margin: auto auto 10rem auto;
  width: 60%;
  font-family: 'Source Serif Pro',serif;
  text-align: justify;
  line-height: 1.5;
  font-size: 1.5rem;
}
.water-content {
  margin: auto auto 10rem auto;
  width: 60%;
  text-align: justify;
  font-family: 'Source Serif Pro',serif;
  line-height: 1.5;
  font-size: 1.5rem;
}
.charity-content {
  margin: auto auto 10rem auto;
  width: 60%;
  text-align: justify;
  font-family: 'Source Serif Pro',serif;
  line-height: 1.5;
  font-size: 1.5rem;
}

@media screen and (max-width: 769px) {
  .header-contribution-image {
    height: 100%;
  }
  .div-descript {
    width: 100%;
    height: 15rem;
  }
  .title-details {
    font-size: 1.5rem;
    text-align: center;
  }
  .not-change{
    width: auto;
    height: 50vh;
  }

  .containerdetail-text{
    width: 59vh; 
    height : 30vh; 
  }

  .box-underheading {
    height: 15px;
    width: 90px;
  }
  .contribution-item-title-0 {
    font-size: 0.8rem;
  }
  .contribution-item-title-1 {
    font-size: 0.8rem;
  }
  .contribution-item-title-2 {
    font-size: 0.8rem;
  }
  .contribution-item-title-3 {
    font-size: 0.8rem;
  }
  .contribution-item-title-4 {
    font-size: 0.8rem;
  }
  .contribution-item-title-0-hidden {
    margin: 1rem auto;
    font-size: 1rem;
    display: none;
  }
  .contribution-item-title-1-hidden {
    margin: 1rem auto;
    font-size: 1rem;
    display: none;
  }
  .contribution-item-title-2-hidden {
    margin: 1rem auto;
    font-size: 1rem;
    display: none;
  }
  .contribution-item-title-3-hidden {
    margin: 1rem auto;
    font-size: 1rem;
    display: none;
  }
  .contribution-item-title-4-hidden {
    margin: 1rem auto;
    font-size: 1rem;
    display: none;
  }
  .insurance-content {
    margin: auto auto 10rem auto;
    width: 70%;
    font-size: 0.8rem;
  }
  .startup-content {
    margin: auto auto 10rem auto;
    width: 70%;
    text-align: justify;
    line-height: 1.5;
    font-size: 1rem;
  }
  .water-content {
    margin: auto auto 10rem auto;
    width: 70%;
    text-align: justify;
    line-height: 1.5;
    font-size: 1rem;
  }
  .charity-content {
    margin: auto auto 10rem auto;
    width: 70%;
    text-align: justify;
    line-height: 1.5;
    font-size: 1rem;
  }
  .foreign-content {
    margin: auto auto 10rem auto;
    width: 70%;
    text-align: justify;
    line-height: 1.5;
    font-size: 1rem;
  }
}

  //===================================================================================================================
  // Ipad - tablet portrait 
  @media only screen and (min-width: 1024px) and (max-width: 1050px) and (orientation: portrait) {
    .not-change{
      width: auto;
      height: 34rem;
    }
  
    .containerdetail-text{
      width: 47rem; 
      height : 22rem; 
    } 
  
    .contribution-item-title-0 {
      font-size: 1.4rem;
      left: 44%;
    }
    .contribution-item-title-1 {
      font-size: 1.4rem;
    }
    .contribution-item-title-2 {
      font-size: 1.4rem;
    }
    .contribution-item-title-3 {
      font-size: 1.4rem ;
    }
    .contribution-item-title-4 {
      font-size: 1.4rem;
    }
  }
  @media only screen and (min-width: 834px) and (max-width: 1023px) and (orientation: portrait) {
    .not-change{
      width: auto;
      height: 34rem;
    }
  
    .containerdetail-text{
      width: 47rem; 
      height : 22rem; 
    } 
  
    .contribution-item-title-0 {
      font-size: 1.4rem;
    }
    .contribution-item-title-1 {
      font-size: 1.4rem;
    }
    .contribution-item-title-2 {
      font-size: 1.4rem;
    }
    .contribution-item-title-3 {
      font-size: 1.4rem ;
    }
    .contribution-item-title-4 {
      font-size: 1.4rem;
    }
  }
  @media only screen and (min-width: 820px) and (max-width: 833px) and (orientation: portrait) {
    .not-change{
      width: auto;
      height: 34rem;
    }
  
    .containerdetail-text{
      width: 44rem; 
      height : 22rem; 
    } 
  
    .contribution-item-title-0 {
      font-size: 1.4rem;
    }
    .contribution-item-title-1 {
      font-size: 1.4rem;
    }
    .contribution-item-title-2 {
      font-size: 1.4rem;
    }
    .contribution-item-title-3 {
      font-size: 1.4rem ;
    }
    .contribution-item-title-4 {
      font-size: 1.4rem;
    }
  }
  @media only screen and (min-width: 810px) and (max-width: 819px) and (orientation: portrait) {
    .containerdetail-text{
      width: 44rem; 
      height : 22rem; 
    }  
    .not-change{
      width: auto;
      height: 30rem;
    }
    .contribution-item-title-0 {
      font-size: 1.4rem;
    }
    .contribution-item-title-1 {
      font-size: 1.4rem;
    }
    .contribution-item-title-2 {
      font-size: 1.4rem;
    }
    .contribution-item-title-3 {
      font-size: 1.4rem ;
    }
    .contribution-item-title-4 {
      font-size: 1.4rem;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) and (orientation: portrait) {
    .contribution-item-title-0 {
      left: 45%;
    }
  
    .not-change{
      width: auto;
      height: 34rem;
    }
  }
  @media screen and (min-width: 744px) and (max-width: 767px) and (orientation: portrait) {
    .not-change{
      width: auto;
      height: 34rem;
    }
  
    .containerdetail-text{
      width: 36rem; 
      height : 20rem; 
    }
  }

  //==========================================================================================================
  //Phone - landscape 

  @media only screen and (min-width: 1440px) and (max-width: 3000px) and (min-height: 1440px) {
    .not-change{
      width: auto;
      height: 66rem;
    }
    .containerdetail-text{
      width: 82rem; 
      height : 48rem; 
    } 
    .contribution-item-title-0 {
      left: 46%;
      font-size: 1.4rem;
    }
    .contribution-item-title-1 {
      font-size: 1.4rem;
    }
    .contribution-item-title-2 {
      font-size: 1.4rem;
    }
    .contribution-item-title-3 {
      font-size: 1.4rem ;
    }
    .contribution-item-title-4 {
      font-size: 1.4rem;
    }
  }
  @media only screen and (min-width: 667px) and (max-width:927px) and (orientation: landscape) {
    .not-change{
      width: auto;
      height: 24rem;
    }
    .containerdetail-text{
      width: 42rem; 
      height : 18rem; 
    } 
    .contribution-item-title-0 {
      font-size: 1.4rem;
    }
    .contribution-item-title-1 {
      font-size: 1.4rem;
    }
    .contribution-item-title-2 {
      font-size: 1.4rem;
    }
    .contribution-item-title-3 {
      font-size: 1.4rem ;
    }
    .contribution-item-title-4 {
      font-size: 1.4rem;
    }
  }
  @media only screen and (min-width: 568px) and (max-width:666px) and (orientation: landscape) {
    .not-change{
      width: auto;
      height: 16rem;
    }
    .containerdetail-text{
      width: 24rem; 
      height : 11rem; 
    }
    .contribution-item-title-0 {
      left: 45%; 
      font-size : 7pt;
    }
    .contribution-item-title-1 {
      font-size : 7pt;
    }
    .contribution-item-title-2 {
      font-size : 7pt;
    }
    .contribution-item-title-3 {
      font-size : 7pt;
    }
    .contribution-item-title-4 {
      font-size : 7pt;
    }
  } 
  //======================================================================================================
  // Phone - portrait 
  @media only screen and (min-width: 476px) and (max-width: 500px) and (orientation: portrait) {
    .not-change{
      width: auto;
      height: 14rem;
    }
    .containerdetail-text{
      width: 22rem; 
      height : 9rem; 
    }
    .contribution-item-title-0 {
      font-size : 6pt;
    }
    .contribution-item-title-1 {
      font-size : 6pt;
    }
    .contribution-item-title-2 {
      font-size : 6pt;
    }
    .contribution-item-title-3 {
      font-size : 6pt;
    }
    .contribution-item-title-4 {
      font-size : 6pt;
    }
  }
  @media only screen and (min-width: 390px) and (max-width:475px) and (orientation: portrait) {
    .not-change{
      width: auto;
      height: 14rem;
    }
    .containerdetail-text{
      width: 19rem; 
      height : 9rem; 
    }
    .contribution-item-title-0 {
      font-size : 5.5pt;
    }
    .contribution-item-title-1 {
      font-size : 5.5pt;
    }
    .contribution-item-title-2 {
      font-size : 5.56pt;
    }
    .contribution-item-title-3 {
      font-size : 5.5pt;
    }
    .contribution-item-title-4 {
      font-size : 5.5pt;
    }
  }

  @media only screen and (min-width: 375px) and (max-width: 389px) and (orientation: portrait) {
    .not-change{
      width: auto;
      height: 14rem;
    }
    .containerdetail-text{
      width: 18rem; 
      height : 8.5rem; 
    }
    .contribution-item-title-0 {
      font-size : 5pt;
    }
    .contribution-item-title-1 {
      font-size : 5pt;
    }
    .contribution-item-title-2 {
      font-size : 5pt;
    }
    .contribution-item-title-3 {
      font-size : 5pt;
    }
    .contribution-item-title-4 {
      font-size : 5pt;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 370px) and (orientation: portrait){
    .title-details {
      font-size: 14pt;
      text-align: center;
  }
    .charity-content, .foreign-content, .startup-content, .water-content, .insurance-content {
      font-size: 11pt;
      line-height: 1.5;
      margin: auto auto 10rem;
      text-align: justify;
      width: 70%;
  }

    .not-change{
      width: auto;
      height: 16rem;
    }
    .containerdetail-text{
      width: 16rem; 
      height : 7.5rem; 
    }

    .contribution-item-title-0 {
      font-size : 5pt;
    }
    .contribution-item-title-1 {
      font-size : 5pt;
    }
    .contribution-item-title-2 {
      font-size : 5pt;
    }
    .contribution-item-title-3 {
      font-size : 5pt;
    }
    .contribution-item-title-4 {
      font-size : 5pt;
    }
  }

