.div-descript {
  background-repeat: no-repeat;
  background-size: cover;
  width: auto;
  height: 56.82vh;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  background-position: center;
}

.title-contrib {
  //position center in the container
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);

  //format text
  text-transform: uppercase;
  text-align: center;
  font-size: 3.1rem;
  font-family: 'Source Serif Pro',serif;
  font-weight: bold;
}

.light-theme {
  color: rgb(241, 242, 242);
}

.dark-theme {
  color: rgb(109, 110, 113);
}
.dotStyle {
  width: '50px';
  height: '50px';
}
.lotus-background {
  //width: 153.03vh;
  //height: 91.26vh;
  width: 44rem;
  height: auto;
}

.lotus-flex {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  bottom: 0px;
  justify-content: center;
  align-items: center;
}

.lotus-containtext {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 70rem;
  height: 31rem;
}

.lotus-container {
  position: relative;
  width: 100%;
  height: 31rem;
}

.leaf-one {
  position: absolute;
  top: 34%;
  left: 0%;

  color: black;
  text-transform: uppercase;
  font-size: 17pt;
  font-weight: bold;
  padding: 1vh;
  font-family: 'Source Serif Pro',serif;
  border-radius: 5vh;
  border : 1px solid #C5982D; 

}

.leaf-two {
  position: absolute;
  top: 8%;
  left: 18%;

  color: black;
  text-transform: uppercase;
  font-size: 17pt;
  font-family: 'Source Serif Pro',serif;
  font-weight: bold;
  padding: 1vh;
  border-radius: 5vh;
  border : 1px solid #C5982D; 
}

.leaf-three {
  position: absolute;
  top: 0%;
  left: 44%;

  color: black;
  text-transform: uppercase;
  font-size: 17pt;
  font-weight: bold;
  font-family: 'Source Serif Pro',serif;
  padding: 1vh;
  border-radius: 5vh;
  border : 1px solid #C5982D; 
}

.leaf-four {
  position: absolute;
  top: 8%;
  right: 18%;

  color: black;
  text-transform: uppercase;
  font-family: 'Source Serif Pro',serif;
  font-size: 17pt;
  font-weight: bold;
  padding: 1vh;
  border-radius: 5vh;
  border : 1px solid #C5982D; 
}

.leaf-five {
  position: absolute;
  top: 34%;
  right: 5%;

  color: black;
  text-transform: uppercase;
  font-size: 17pt;
  font-weight: bold;
  padding: 1vh;
  font-family: 'Source Serif Pro',serif;
  border-radius: 5vh;
  border : 1px solid #C5982D; 
}

.container-bar {
  position: relative;
  width: 100%;
  height: 18rem;
}

.contrib-bar {
  width: 16rem;
  height: 1rem;
  background-color: #c69703;

  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.time_desp {
  height: 16vh;
  width: 100%;
  background-color: #f9efd4;

  display: flex;
  font-size: 1.7rem;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-family: 'Source Serif Pro',serif;
  font-weight: bold;
}

.leaf-one:hover , .leaf-two:hover , .leaf-three:hover, .leaf-four:hover, .leaf-five:hover {
  background-color: #C5982D;
  color: white
}

.carousel { 
  display: flex; 
  justify-content: center;
}

.carousel .slide{
  height: 45rem;
  width: 55rem;
  padding: 30px 60px;
}

.carousel-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img_container{
  width: 100%;
  height : 100%;
  position: relative;
  margin : 0px 20px;
}

.div-hover{
  width: 100%; 
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  box-shadow:inset -50px -180px 80px 0 #1C1C1C;
  border-radius:3rem;
}

.title-collection{
  color: white;
  font-family: 'Source Serif Pro',serif;
  font-weight: bold;
  font-size: 16pt;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px 20px;
}

.numimg-collection{
  color: white;
  font-family: 'Source Serif Pro',serif;
  font-size: 16pt;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  padding: 10px 20px;
  right: 0;
}

.carousel .slide .img_container img{ 
  max-height : auto;
  width:  100%;
  height: 100%;
  object-fit:contain;
  border-radius: 3rem;
}

.img_caur{
  height: auto;
  width: auto;
}


//===================================================================================================
//Ipad or tablets

@media only screen and (min-width: 1024px) and (max-width: 1050px) and (orientation: portrait) {
  .lotus-containtext {
    width: 50rem;
    height: 31rem;
  }

  .lotus-background{
    width: 44rem;
    height: auto;
  }

  .lotus-container{
    height: 31rem;
  }

  .leaf-one {
    top: 40%;
    left:0%;
    font-size: 10pt;
  }

  .leaf-two {
    top: 12%;
    left: 14%; 
    font-size: 10pt;
  }

  .leaf-three {
    top: 0%;
    left: 44%;
    font-size: 10pt;
  }

  .leaf-four {
    top: 12%;
    right: 14%; 
    font-size: 10pt;
  }

  .leaf-five {
    top: 40%;
    font-size: 10pt;
  }
  .carousel .slide{
    height: 18rem;
    width: 30rem;
    padding: 15px 15px;
  }

  .title-collection {
    font-size: 12pt,
  }
  .numimg-collection{
    font-size: 12pt
  }
  .div-hover{
    width: 100%; 
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-shadow:inset -50px -140px 80px 0 #1C1C1C;
    border-radius: 10%;
  }
  .carousel .slide .img_container img{
    border-radius: 10%;
  }
}

@media only screen and (min-width: 834px) and (max-width: 1023px) and (orientation: portrait) {
  .title-contrib {
    top: 55%;
  }

  .para{
    font-size: 19pt;
    font-family: 'Source Serif Pro', serif;
  }
  .lotus-containtext {
    width: 44rem;
    height: 26rem;
  }

  .lotus-background{
    width: 36rem;
    height: auto;
  }

  .lotus-container{
    height: 26rem;
  }

  .leaf-one {
    top: 40%;
    left:0%;
    font-size: 10pt;
  }

  .leaf-two {
    top: 12%;
    left: 14%; 
    font-size: 10pt;
  }

  .leaf-three {
    top: 0%;
    left: 44%;
    font-size: 10pt;
  }

  .leaf-four {
    top: 12%;
    right: 14%; 
    font-size: 10pt;
  }

  .leaf-five {
    top: 40%;
    font-size: 10pt;
  }
  .carousel .slide{
    height: 18rem;
    width: 30rem;
    padding: 15px 15px;
  }

  .title-collection {
    font-size: 12pt,
  }
  .numimg-collection{
    font-size: 12pt
  }
  .div-hover{
    width: 100%; 
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-shadow:inset -50px -140px 80px 0 #1C1C1C;
    border-radius: 10%;
  }
  .carousel .slide .img_container img{
    border-radius: 10%;
  }
}

@media only screen and (min-width: 820px) and (max-width: 833px) and (orientation: portrait) {
  .title-contrib {
    top: 55%;
  }

  .para{
    font-size: 19pt;
    font-family: 'Source Serif Pro', serif;
  }
  .lotus-containtext {
    width: 44rem;
    height: 26rem;
  }

  .lotus-background{
    width: 36rem;
    height: auto;
  }

  .lotus-container{
    height: 26rem;
  }

  .leaf-one {
    top: 40%;
    left:0%;
    font-size: 10pt;
  }

  .leaf-two {
    top: 12%;
    left: 14%; 
    font-size: 10pt;
  }

  .leaf-three {
    top: 0%;
    left: 44%;
    font-size: 10pt;
  }

  .leaf-four {
    top: 12%;
    right: 14%; 
    font-size: 10pt;
  }

  .leaf-five {
    top: 40%;
    font-size: 10pt;
  }
  .carousel .slide{
    height: 18rem;
    width: 30rem;
    padding: 15px 15px;
  }

  .title-collection {
    font-size: 12pt,
  }
  .numimg-collection{
    font-size: 12pt
  }
  .div-hover{
    width: 100%; 
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-shadow:inset -50px -140px 80px 0 #1C1C1C;
    border-radius: 10%;
  }
  .carousel .slide .img_container img{
    border-radius: 10%;
  }

}
@media only screen and (min-width: 810px) and (max-width: 819px) and (orientation: portrait) {
  .title-contrib {
    top: 55%;
  }

  .para{
    font-size: 19pt;
    font-family: 'Source Serif Pro', serif;
  }
  .lotus-containtext {
    width: 44rem;
    height: 26rem;
  }

  .lotus-background{
    width: 36rem;
    height: auto;
  }

  .lotus-container{
    height: 26rem;
  }
  
  .leaf-one {
    top: 40%;
    left:0%;
    font-size: 10pt;
  }

  .leaf-two {
    top: 12%;
    left: 14%; 
    font-size: 10pt;
  }

  .leaf-three {
    top: 0%;
    left: 44%;
    font-size: 10pt;
  }

  .leaf-four {
    top: 12%;
    right: 14%; 
    font-size: 10pt;
  }

  .leaf-five {
    top: 40%;
    font-size: 10pt;
  }
  .carousel .slide{
    height: 18rem;
    width: 30rem;
    padding: 15px 15px;
  }

  .title-collection {
    font-size: 12pt,
  }
  .numimg-collection{
    font-size: 12pt
  }
  .div-hover{
    width: 100%; 
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-shadow:inset -50px -140px 80px 0 #1C1C1C;
    border-radius: 10%;
  }
  .carousel .slide .img_container img{
    border-radius: 10%;
  }
}


@media only screen and (min-width: 768px) and (max-width: 809px) and (orientation: portrait) {

  .para{
    font-size: 19pt;
    font-family: 'Source Serif Pro', serif;
  }
  .time_desp {
    height: 3rem;
    width: 100%;
    background-color: #f9efd4;
  
    display: flex;
    font-size: 1.0rem;
    justify-content: center;
    align-items: center;
  
    text-align: center;
    font-weight: bold;
  }

  .div-descript{
    height: 16rem;
  }

  .title-contrib {
    top: 60%;
    font-size: 2rem;
  }

  .lotus-containtext {
    width: 40rem;
    height: 23rem;
  }


  .lotus-background{
    width: 32rem;
    height: auto;
  }

  .lotus-container{
    height: 23rem;
  }

  .leaf-one {
    top: 40%;
    left:0%;
    font-size: 10pt;
  }

  .leaf-two {
    top: 14%;
    left: 14%; 
    font-size: 10pt;
  }

  .leaf-three {
    top: 0%;
    left: 44%;
    font-size: 10pt;
  }

  .leaf-four {
    top: 14%;
    right: 14%; 
    font-size: 10pt;
  }

  .leaf-five {
    top: 40%;
    font-size: 10pt;
  }
  .carousel .slide{
    height: 18rem;
    width: 30rem;
    padding: 15px 15px;
  }

  .title-collection {
    font-size: 12pt,
  }
  .numimg-collection{
    font-size: 12pt
  }
  .div-hover{
    width: 100%; 
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-shadow:inset -50px -140px 80px 0 #1C1C1C;
    border-radius: 10%;
  }
  .carousel .slide .img_container img{
    border-radius: 10%;
  }

}

@media screen and (min-width: 744px) and (max-width: 767px) and (orientation: portrait) {
  .para{
    font-size: 19pt;
    font-family: 'Source Serif Pro', serif;
  }
  .time_desp {
    height: 3rem;
    width: 100%;
    background-color: #f9efd4;
  
    display: flex;
    font-size: 1.0rem;
    justify-content: center;
    align-items: center;
  
    text-align: center;
    font-weight: bold;
  }

  .div-descript{
    height: 16rem ;
  }

  .title-contrib {
    top: 60%;
    font-size: 2rem;
  }
  .lotus-containtext {
    width: 40rem;
    height: 26rem;
  }

  .lotus-background{
    width: 36rem;
    height: auto;
  }

  .lotus-container{
    height: 26rem;
  }

  .leaf-one {
    top: 40%;
    left:0%;
    font-size: 10pt;
  }

  .leaf-two {
    top: 14%;
    left: 14%; 
    font-size: 10pt;
  }

  .leaf-three {
    top: 0%;
    left: 44%;
    font-size: 10pt;
  }

  .leaf-four {
    top: 14%;
    right: 14%; 
    font-size: 10pt;
  }

  .leaf-five {
    top: 40%;
    font-size: 10pt;
  }
  
  .carousel .slide{
    height: 18rem;
    width: 35rem;
    padding: 15px 5px;
  }

  .title-collection {
    font-size: 12pt,
  }
  .numimg-collection{
    font-size: 12pt
  }
  .div-hover{
    width: 100%; 
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-shadow:inset -50px -140px 80px 0 #1C1C1C;
    border-radius: 5%;
  }
}


//===========================================================================================

//Landscape phoneeee

@media only screen and (min-width: 667px) and (max-width:927px) and (orientation: landscape) {
  .para{
    font-size: 19pt;
    font-family: 'Source Serif Pro', serif;
  }
  .lotus-containtext {
    width: 29rem;
    height: 14rem;
  }

  .lotus-background{
    width: 18rem;
    height: auto;
  }

  .lotus-container{
    height: 14rem;
  }
    .leaf-one {
    top: 40%;
    left:0%;
    font-size: 10pt;
  }

  .leaf-two {
    top: 14%;
    left: 14%; 
    font-size: 10pt;
  }

  .leaf-three {
    top: 0%;
    left: 43%;
    font-size: 10pt;
  }

  .leaf-four {
    top: 14%;
    right: 14%; 
    font-size: 10pt;
  }

  .leaf-five {
    top: 40%;
    font-size: 10pt;
  }
  
  .carousel .slide{
    height: 18rem;
    width: 35rem;
    padding: 15px 5px;
  }

  .title-collection {
    font-size: 12pt,
  }
  .numimg-collection{
    font-size: 12pt
  }
  .div-hover{
    width: 100%; 
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-shadow:inset -50px -140px 80px 0 #1C1C1C;
    border-radius: 5%;
  }
}

@media only screen and (min-width: 568px) and (max-width: 666px) and (orientation: landscape) {
  .para{
    font-size: 19pt;
    font-family: 'Source Serif Pro', serif;
  }
  .lotus-containtext {
    width: 28rem;
    height: 13.5rem;
  }

  .lotus-background{
    width: 18rem;
    height: auto;
  }

  .lotus-container{
    height: 13.5rem;
  }
    .leaf-one {
    top: 40%;
    left:0%;
    font-size: 10pt;
  }

  .leaf-two {
    top: 14%;
    left: 14%; 
    font-size: 10pt;
  }

  .leaf-three {
    top: 0%;
    left: 41%;
    font-size: 10pt;
  }

  .leaf-four {
    top: 14%;
    right: 14%; 
    font-size: 10pt;
  }

  .leaf-five {
    top: 40%;
    font-size: 10pt;
  }
  .carousel .slide{
    height: 18rem;
    width: 35rem;
    padding: 15px 5px;
  }

  .title-collection {
    font-size: 12pt,
  }
  .numimg-collection{
    font-size: 12pt
  }
  .div-hover{
    width: 100%; 
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-shadow:inset -50px -140px 80px 0 #1C1C1C;
    border-radius: 5%;
  } 
}


//================================================================================================

//Phoneee



@media only screen and (min-width: 476px) and (max-width: 500px) and (orientation: portrait) {
  .para{
    font-size: 19pt;
    font-family: 'Source Serif Pro', serif;
  }
  .time_desp {
    height: 3rem;
    width: 100%;
    background-color: #f9efd4;
  
    display: flex;
    font-size: 1.0rem;
    justify-content: center;
    align-items: center;
  
    text-align: center;
    font-weight: bold;
  }

  .div-descript{
    height: 16rem;
  }

  .title-contrib {
    top: 60%;
    font-size: 2rem;
  }
  .lotus-containtext {
    width: 26rem;
    height: 14rem;
  }


  .lotus-background{
    width: 18.5rem;
    height: auto;
  }

  .lotus-container{
    height: 14rem;
  }

  .leaf-one {
    top: 40%;
    left:0%;
    font-size: 6pt;
  }

  .leaf-two {
    top: 14%;
    left: 14%; 
    font-size: 6pt;
  }

  .leaf-three {
    top: 0%;
    left: 42%;
    font-size: 6pt;
  }

  .leaf-four {
    top: 14%;
    right: 14%; 
    font-size: 6pt;
  }

  .leaf-five {
    top: 40%;
    font-size: 6pt;
  }
    .carousel .slide{
    height: 18rem;
    width: 35rem;
    padding: 15px 5px;
  }

  .title-collection {
    font-size: 12pt,
  }
  .numimg-collection{
    font-size: 12pt
  }
  .div-hover{
    width: 100%; 
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-shadow:inset -50px -140px 80px 0 #1C1C1C;
    border-radius: 5%;
  }
}
@media only screen and (min-width: 428px) and (max-width:474px) and (orientation: portrait) {
  .para{
    font-size: 19pt;
    font-family: 'Source Serif Pro', serif;
  }
  .time_desp {
    height: 3rem;
    width: 100%;
    background-color: #f9efd4;
  
    display: flex;
    font-size: 1.0rem;
    justify-content: center;
    align-items: center;
  
    text-align: center;
    font-weight: bold;
  }

  .div-descript{
    height: 16rem;
  }

  .title-contrib {
    top: 60%;
    font-size: 2rem;
  }
  .lotus-containtext {
    width: 24rem;
    height: 13.4rem;
  }


  .lotus-background{
    width: 18.5rem;
    height: auto;
  }

  .lotus-container{
    height: 13.4rem;
  }

  .leaf-one {
    font-size: 5pt;
  }

  .leaf-two {
    font-size: 5pt;
  }

  .leaf-three {
    left: 42.5%;
    font-size: 5pt;
  }

  .leaf-four {
    font-size: 5pt;
  }

  .leaf-five {
    font-size: 5pt;
  }
  .carousel .slide{
    height: 18rem;
    width: 35rem;
    padding: 15px 5px;
  }

  .title-collection {
    font-size: 12pt,
  }
  .numimg-collection{
    font-size: 12pt
  }
  .div-hover{
    width: 100%; 
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-shadow:inset -50px -140px 80px 0 #1C1C1C;
    border-radius: 5%;
  }
}

@media only screen and (min-width: 414px) and (max-width:420px) and (orientation: portrait) {
  .para{
    font-size: 19pt;
    font-family: 'Source Serif Pro', serif;
  }
  .time_desp {
    height: 3rem;
    width: 100%;
    background-color: #f9efd4;
  
    display: flex;
    font-size: 1.0rem;
    justify-content: center;
    align-items: center;
  
    text-align: center;
    font-weight: bold;
  }

  .div-descript{
    height: 16rem;
  }

  .title-contrib {
    top: 60%;
    font-size: 2rem;
  }
  .lotus-containtext {
    width: 22rem;
    height: 12.4rem;
  }


  .lotus-background{
    width: 16.5rem;
    height: auto;
  }

  .lotus-container{
    height: 12.4rem;
  }

  .leaf-one {
    font-size: 5pt;
  }

  .leaf-two {
    font-size: 5pt;
  }

  .leaf-three {
    left: 42%;
    font-size: 5pt;
  }

  .leaf-four {
    font-size: 5pt;
  }

  .leaf-five {
    font-size: 5pt;
  }
  .carousel .slide{
    height: 18rem;
    width: 35rem;
    padding: 15px 5px;
  }

  .title-collection {
    font-size: 12pt,
  }
  .numimg-collection{
    font-size: 12pt
  }
  .div-hover{
    width: 100%; 
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-shadow:inset -50px -140px 80px 0 #1C1C1C;
    border-radius: 5%;
  }
}

@media only screen and (min-width: 390px) and (max-width: 413px) and (orientation: portrait){
  .para{
    font-size: 19pt;
    font-family: 'Source Serif Pro', serif;
  }
  .time_desp {
    height: 3rem;
    width: 100%;
    background-color: #f9efd4;
  
    display: flex;
    font-size: 1.0rem;
    justify-content: center;
    align-items: center;
  
    text-align: center;
    font-weight: bold;
  }

  .div-descript{
    height: 16rem;
  }
  
  .title-contrib {
    top: 60%;
    font-size: 1.8rem;
  }

  .lotus-containtext {
    width: 22rem;
    height: 13rem;
  }


  .lotus-background{
    width: 17.5rem;
    height: auto;
  }

  .lotus-container{
    height: 13rem;
  }

  .leaf-one {
    top: 36%;
    font-size: 4.5pt;
  }

  .leaf-two {
    top: 12%;
    font-size: 4.5pt;
  }

  .leaf-three {
    left: 43%;
    font-size: 4.5pt;
  }

  .leaf-four {
    top: 12%;
    font-size: 4.5pt;
  }

  .leaf-five {
    top: 36%;
    font-size: 4.5pt;
  }
  .carousel .slide{
    height: 18rem;
    width: 35rem;
    padding: 15px 5px;
  }

  .title-collection {
    font-size: 12pt,
  }
  .numimg-collection{
    font-size: 12pt
  }
  .div-hover{
    width: 100%; 
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-shadow:inset -50px -140px 80px 0 #1C1C1C;
    border-radius: 5%;
  }
}


@media only screen and (min-width: 375px) and (max-width: 389px) and (orientation: portrait) {
  .para{
    font-size: 19pt;
    font-family: 'Source Serif Pro', serif;
  }
  .time_desp {
    height: 3rem;
    width: 100%;
    background-color: #f9efd4;
  
    display: flex;
    font-size: 1.0rem;
    justify-content: center;
    align-items: center;
  
    text-align: center;
    font-weight: bold;
  }

  .div-descript{
    height: 16rem;
    width: 100%;
  }
  .title-contrib {
    top: 60%;
    font-size: 1.8rem;
  }

  .lotus-containtext {
    width: 20rem;
    height: 9.5rem;
  }


  .lotus-background{
    width: 12.5rem;
    height: auto;
  }

  .lotus-container{
    height: 9.5rem;
  }

  .leaf-one {
    font-size: 4.5pt;
  }

  .leaf-two {
    top: 12%;
    font-size: 4.5pt;
  }

  .leaf-three {
    left: 42%;
    font-size: 4.5pt;
  }

  .leaf-four {
    top: 12%;
    font-size: 4.5pt;
  }

  .leaf-five {
    font-size: 4.5pt;
  }
  .carousel .slide{
    height: 18rem;
    width: 35rem;
    padding: 15px 5px;
  }

  .title-collection {
    font-size: 12pt,
  }
  .numimg-collection{
    font-size: 12pt
  }
  .div-hover{
    width: 100%; 
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-shadow:inset -50px -140px 80px 0 #1C1C1C;
    border-radius: 5%;
  }
} 

@media only screen and (min-width: 320px) and (max-width: 370px) and (orientation: portrait){
  .time_desp {
    height: 3rem;
    width: 100%;
    background-color: #f9efd4;
  
    display: flex;
    font-size: 1.0rem;
    justify-content: center;
    align-items: center;
  
    text-align: center;
    font-weight: bold;
  }

  .carousel .slide{
    height: 18rem;
    width: 35rem;
    padding: 15px 5px;
  }

  .title-collection {
    font-size: 12pt,
  }
  .numimg-collection{
    font-size: 12pt
  }
  .div-hover{
    width: 100%; 
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-shadow:inset -50px -140px 80px 0 #1C1C1C;
    border-radius: 5%;
  }
  .div-descript{
    height: 16rem;
  }

  .title-contrib {
    top: 60%;
    font-size: 1.5rem;
  }
  .lotus-background{
    width: 12.5rem;
    height: auto;
  }

  .lotus-container{
    height: 9.5rem;
  }

  .lotus-containtext {
    width: 18rem;
    height: 9.5rem;
  }

  .leaf-one {
    font-size: 4.3pt;
  }

  .leaf-two {
    top: 12%;
    font-size: 4.3pt;
  }

  .leaf-three {
    left: 42%;
    font-size: 4.3pt;
  }

  .leaf-four {
    top: 12%;
    font-size: 4.3pt;
  }

  .leaf-five {
    font-size: 4.3pt;
  }
}
