/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots-style
{
    position: static;
    background-color: #fdf7e9;


    display: flexbox;

    padding: 5rem;
    margin-top: 5rem;
    margin-bottom: 10rem;

    list-style: none;

    text-align: center;
}

.slick-dots-style li
{

    position: static;
    white-space: nowrap;
    display: inline-block;
    background-size:contain;
    padding: 0;

    cursor: pointer;
}
.slick-dots-style li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots-style li button:hover,
.slick-dots-style li button:focus
{
    outline: none;
}
.slick-dots-style li button:hover:before,
.slick-dots-style li button:focus:before
{
    opacity: 1;
}
.slick-dots-style li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots-style li.slick-active button:before
{
    opacity: .75;
    color: black;
}
.row:after {
    content: "";
    display: table;
    clear: both;
}

@media only screen and (max-width: 375px) {
    .slick-dots-style
{
    position: static;
    background-color: #fdf7e9;


    display: flexbox;

    padding: 2rem;
    margin-top: 5rem;
    margin-bottom: 10rem;

    list-style: none;

    text-align: center;
}

.slick-dots-style li
{

    position: static;
    white-space: nowrap;
    display: inline-block;
    background-size:contain;
    padding: 0;

    cursor: pointer;
}

.div-descript {
    background-repeat: no-repeat;
    background-size: cover;
    width: auto;
    height: 10rem;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    background-position: center;
  }
}


