.footer
{
    width: 100%;
    margin-top: 40px;

}

.footer-img {
    max-width: 100%;
    max-height: 100%;
   }
   

.footer_logo
{
    position: relative;
    width: 100%;
    display: block;
}
.footer_logo-wrapper
{
    width: 4.5rem; 
    height: 4.2rem;
    max-height: 4.6rem; 
    background-color: #414042; 
    border-top-left-radius: 1.5vh; 
    border-top-right-radius: 1.5vh; 
    overflow: hidden; 
    display: block;
    top: 0;
    float: right;
    margin-right: 1.9rem;
}

.contact-container{
    display: flex;
    padding-top: 1%;

    font-family: 'Source Serif Pro', serif; 
    margin: 0px;
    font-size: 1.2rem; 
    font-weight: 500;
}

// size footer bar 
.footer_text-wrapper
{
    height: fit-content;
    padding: 2%; 
    width: 100%; 
    background-color: #C69703;  
    display: flex; 
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
}
.footer_text
{
    font-family: 'Source Serif Pro', serif; 
    margin: 0px;
    font-size: 1.2rem; 
    font-weight: 500;
}

.contact-icon-container{
    display: flex;
}

.contact-icon {
    height: auto; 
    width: 2rem;
    padding: 0rem 1.2rem;
    color: black
}

.contact-icon:hover{
    color: white
}

// Responsive

// - footer portrait mobile
@media only screen and (min-width: 320px) and (max-width: 374px) and (orientation: portrait) {
    .footer_text{
        font-size: 0.5rem; 
        text-align: center;
        padding: 0 1rem;
    }
    .footer_logo-wrapper
{
    width: 4.5rem; 
    height: 4.2rem;
    max-height: 4.6rem; 
    background-color: #414042; 
    border-top-left-radius: 1.5vh; 
    border-top-right-radius: 1.5vh; 
    overflow: hidden; 
    display: block;
    top: 0;
    float: right;
    margin-right: 1.9rem;
}
.contact-container{
    flex-direction: column;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 

    font-size: 0.5rem; 
}

.contact-icon {
    font-size: 1.5rem; 
    padding: 0rem;
}
}
@media only screen and (min-width: 375px) and (max-width: 767px) and (orientation: portrait) {
    .footer_text{
        font-size: 0.7rem; 
        text-align: center;
        padding: 0 1rem;
    }
    .footer_logo-wrapper
{
    width: 4.5rem; 
    height: 4.2rem;
    max-height: 4.6rem; 
    background-color: #414042; 
    border-top-left-radius: 1.5vh; 
    border-top-right-radius: 1.5vh; 
    overflow: hidden; 
    display: block;
    top: 0;
    float: right;
    margin-right: 1.9rem;
}
.contact-container{
    flex-direction: column;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 

    font-size: 0.7rem; 
}

.contact-icon {
    font-size: 1.5rem; 
    padding: 0rem;
}
}


// - footer landscape mobile
@media only screen and (min-width: 568px) and (max-width: 666px) and (orientation: landscape){
    .footer_text{
        font-size: 0.8rem; 
        text-align: center;
        padding: 0 40px;
    }
    .footer_logo-wrapper
{
    width: 4.5rem; 
    height: 4.2rem;
    max-height: 4.6rem; 
    background-color: #414042; 
    border-top-left-radius: 1.5vh; 
    border-top-right-radius: 1.5vh; 
    overflow: hidden; 
    display: block;
    top: 0;
    float: right;
    margin-right: 1.9rem;
}
}
@media only screen and (min-width: 667px) and (max-width: 927px) and (orientation: landscape){
    .footer_text{
        font-size: 0.8rem; 
        text-align: center;
        padding: 0 40px;
    }
    .footer_logo-wrapper
{
    width: 4.5rem; 
    height: 4.2rem;
    max-height: 4.6rem; 
    background-color: #414042; 
    border-top-left-radius: 1.5vh; 
    border-top-right-radius: 1.5vh; 
    overflow: hidden; 
    display: block;
    top: 0;
    float: right;
    margin-right: 1.9rem;
}
}

// - footer portrait tablets/ipad
@media only screen and (min-width: 768px) and (max-width: 1025px) and (orientation: portrait){
    .footer_text{
        font-size: 1.2rem; 
        text-align: center;
        padding: 0 40px;
    }
    .footer_logo-wrapper
{
    width: 4.5rem; 
    height: 4.2rem;
    max-height: 4.6rem; 
    background-color: #414042; 
    border-top-left-radius: 1.5vh; 
    border-top-right-radius: 1.5vh; 
    overflow: hidden; 
    display: block;
    top: 0;
    float: right;
    margin-right: 1.9rem;
}
}
