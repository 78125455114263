@import 'styles/mixins';
.box-imglien-desktop {
  min-width: 34%;
  height: auto;
  margin: auto;
}
.quotetitle-container {
  display: table-cell;
  vertical-align: middle;
}
.testquotes-container {
  margin: auto;
  width: 100%;
  display: flex;
  padding-top: 2%;
}
.testquotes-img-holder {
  width: 80%;
}
.box-imglien-mobile {
  display: none;
}
.box-quotestitle {
  width: 80%;
  text-align: center;
  display: table;
}
.title-one {
  text-align: center;
  font-size: 2.8rem;
  font-weight: bold;
  font-family: 'Source Serif Pro', serif;
}
.title-two {
  text-align: center;
  font-size: 3.5rem;
  font-weight: bold;
  font-family: 'Source Serif Pro', serif;
  color: #c69703;
}
.slider_header {
  font-weight: 36px;
  font-weight: 700;
  font-size: 1.5rem;
  font-family: 'Source Serif Pro', serif;
}
@media only screen and (min-width: 1920px) {
  .testquotes-container {
    margin: auto;
    width: 100%;
    display: flex;
    padding-top: 5%;
}
  .title-one {
    text-align: center;
    font-size: 3.8rem;
    font-weight: bold;
    font-family: "Source Serif Pro", serif;
}
  .title-two {
    text-align: center;
    font-size: 5.5rem;
    font-weight: bold;
    font-family: "Source Serif Pro", serif;
    color: #c69703;
}
  .testquotes-img-holder {
    width: 49%;
}
}
@media only screen and (min-width: 1440px) {
  .testquotes-container {
    margin: auto;
    width: 100%;
    display: flex;
    padding-top: 16%;
}
  .testquotes-img-holder {
    width:40%;
}
}
@media only screen and (min-width: 822px) {
  .testquotes-container {
    margin: auto;
    width: 100%;
    display: flex;
    padding-top: 16%;
}
  .testquotes-img-holder {
    width:50%;
}
}
@media only screen and (max-width: 821px) {
  .box-quotestitle {
    width: 80%;
    text-align: center;
    display: table;
}
  .testquotes-img-holder {
    width: 53%;
}
  .title-one {
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    font-family: "Source Serif Pro", serif;
}
  .title-two {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    font-family: "Source Serif Pro", serif;
    color: #c69703;
}
}
@media only screen and (max-width: 450px) {
  .box-imglien-desktop {
    display: none;
  }
  .box-quotestitle {
    width: 85%;
    text-align: center;
}
  .testquotes-container {
    margin: auto;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10%;
}
  .quotetitle-container {
    padding: 10px;
}
  .box-imglien-mobile {
    display: block;
    width: 80%;
    margin: auto;
}
  .title-one {
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    font-family: "Source Serif Pro", serif;
}
  .title-two {
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    font-family: "Source Serif Pro", serif;
    color: #c69703;
}
  .testquotes-img-holder {
    width: 76%;
    padding: 0px;
    display: flex;
    max-height: 20rem;
}
}
