@import 'styles/mixins';
.box-heading {
  margin-top: 0;
}
.quote-container {
  background-image: url('../views/Quotes/assets/quotes-07.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 450px;
  width: 100%;
}
.quote-title {
  font-size: 3rem;
  font-weight: bold;
  color: white;
  text-align: center;
  display: block;
  padding: 18rem 0;
  text-transform: uppercase;
}
.box-underheader {
  background-color: #c69703;
  height: 38px;
  width: 240px;
  margin: auto;
}
.content-quote {
  margin: 5rem auto 10rem auto;
  text-align: center;
}
.quote-heading {
  text-align: center;
  font-family: 'Source Serif Pro', serif;
  font-weight: bold;
  font-size: 3rem;
}
.box-quotedata {
  width: 100%;
  height: 100%;
}
.quote-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  justify-content: center;
}

.gallery-list{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  justify-content: center;
}

.gallery-item:hover{
  filter: brightness(60%);
}

.gallery-item {
  align-items: center;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5); 
  
}

.gallery-textTitle {
  font-family: 'Source Serif Pro', serif;
  font-weight: bold;
  padding : 10px 0px;
  margin: 0px;
} 

.gallery-img {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.quote-item-box {
  align-items: center;
  padding: auto;
}

.quote-sharktank {
  font-size: 2rem;
  padding: 2rem;
  font-family: 'Source Serif Pro', serif;
  font-style: bold;
}
.quote-charity {
  font-size: 2rem;
  padding: 2rem;
  font-family: 'Source Serif Pro', serif;
  font-style: bold;
}
.quote-book {
  font-size: 2rem;
  padding: 2rem;
  font-family: 'Source Serif Pro', serif;
  font-style: bold;
}
// .quote-image img {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }
.quote-image {
  width: 91%;
  height: 45rem;
  max-width: 35rem;
  object-fit: cover;
  margin: auto;
}
.box-footering {
  display: block;
  height: 11rem;
  margin: 8rem auto;
  background-color: #f9efd4;
}
.box-footering-hidden {
  display: none;
}
.quote-footerone {
  text-align: center;
  padding: 2rem;
  font-family: 'Source Serif Pro', serif;
  font-weight: bold;
  display: block;
}
.quote-footertwo {
  text-align: center;
  font-size: 4rem;
  font-family: 'Source Serif Pro', serif;
  font-weight: bold;
  display: block;
  margin: -3rem auto;
}

.ant-btn.ant-btn-round.ant-btn-dash {
  @include textClamp(1);
  margin: 1.5rem auto;
  height: auto;
}
.ant-btn.ant-btn-round.ant-btn-dash span {
  @include textClamp(2);
}

@include maxWidth(1350px) {
  .quote-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    justify-content: center;
  }

  .gallery-list{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    justify-content: center;
  }
}

@include maxWidth(767px) {
  .quote-container {
    height: 280px;
    width: 100%;
  }
  .box-quotetitle {
    margin-top: 10%;
  }
  .quote-title {
    font-size: 1.5rem;
    text-transform: uppercase;
    color: white;
    font-style: bold;
    text-align: center;
    display: block;
    padding: 30% 5%;
  }
  .box-underheader {
    height: 0.5rem;
    width: 5rem;
    margin: auto auto -26% auto;
  }
  .quote-charity {
    font-size: 1.5rem;
    font-family: 'Source Serif Pro', serif;
    font-style: bold;
    line-height: 1;
  }
  .content-quote {
    margin: 13rem auto 2rem auto;
    display: block;
  }
  .quote-heading {
    font-size: 1.5rem;
  }
  .box-quotedata {
    width: 80%;
    margin: auto auto 5rem auto;
  }
  .quote-list {
    display: block;
  }
  .quote-image {
    width: 100%;
    height: auto;
  }
  .ant-btn.ant-btn-round.ant-btn-dash span {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.6rem;
    font-family: 'Source Serif Pro', serif;
  }
  // .ant-btn {
  //   font-size: 0.8rem;
  //   text-overflow: ellipsis;
  //   text-transform: uppercase;
  //   font-family: 'Source Serif Pro', serif;
  //   display: block;
  //   text-align: center;
  //   padding-left: 1rem;
  //   padding-right: 1rem;
  //   height: auto;
  // }
  .ant-btn-default {
    background-color: #c69703;
    color: white;
    border-color: #c69703;
  }
  .box-footering {
    width: 100%;
    display: block;
    height: 114px;
  }
  .quote-footerone {
    font-weight: bold;
    font-size: 1.5rem;
  }
  .quote-footertwo {
    font-size: 2rem;
    font-weight: bold;
  }
  .quote-sharktank {
    font-size: 1rem;
    padding: 1rem;
  }
  .quote-book {
    font-size: 1rem;
    padding: 1rem;
  }
  .quote-charity {
    font-size: 1rem;
    padding: 1rem;
  }
}
