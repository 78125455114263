@import 'styles/layout';
@import 'styles/antd';
@import 'styles/gallery';
@import 'styles/quotelist';
@import 'styles/variables';
@import 'styles/footer';
@import 'styles/contribute';
@import 'styles/contributiondetail';
@import '/src/styles/slick';
@import '~bootstrap/scss/bootstrap';
@import '~video-react/styles/scss/video-react';
@import 'styles/indexgroup';
@import 'styles/testimonialsslider';
@import 'styles/gallery_home';
@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import 'styles/carouselslider';
@import 'styles/quotestesthome';
@import "styles/biography";
@import "../src/styles/media";
@import "../src/styles/briefintro";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@import "styles/section2";
@import "./styles/mixins";
@import "./styles/news";
html {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
input,
textarea,
select,
button {
  outline: none;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  /* justify-content: space-between; */
  padding: 0px;
  flex-direction: row;
}
input {
  line-height: normal;
}
label,
button {
  cursor: pointer;
}
a {
  text-decoration: none;
}
img {
  display: block;
  max-width: 100%;
}

nav {
  display: inline-flex;
  width: 100%;
  // position: fixed;
  font-family: 'Source Serif Pro', serif;
}
nav #logo {
  width: 7%;
  //margin-left: 15%;
  margin-left: 10vh;
  border-bottom: none;
  padding-bottom: 20px;
}
nav img {
  top: 0;
  margin: 0px;
  height: 90%;
}
nav ul {
  list-style: none;
  height: 80%;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  height: 7vh;
  flex-wrap: wrap;
  margin-right: 10vh;
}
nav ul li {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 20px;
  display:flex;
  width: 22vh; 
  flex-direction: column;
  align-items: center;
  padding: 5px 8px;
}
nav .icon-navbar {
  display: none;
}
nav ul li a {
  color: #231f20;
  //padding-top: 4px;
  //padding-bottom: 4px;
  //justify-content: baseline;
  //width: 148px;
  justify-content: center;
}
nav ul li a:hover{
  animation: colorTransform ease 0.3s;
  color: #cccaae;
  border-bottom: 2px solid #cccaae;
}
nav ul li a.active {
  animation: colorTransform ease 0.3s;
  color: #cccaae;
  border-bottom: 2px solid #cccaae;
  width: fit-content;
}

.lang-container {
  display: flex;
  flex-direction:column;
  position: relative;
  justify-content: center; 
  align-items: center;
}
.dropdown-lang-box {
  height: 4.5rem; 
  width: 10rem;
  background: white; 
  position: absolute;
  z-index: 20;
  top: 3rem;
  text-align: center;
}

.dropdown-lang-box-mobile {
  height: 4.5rem; 
  width: 10rem;
  background: white; 
  z-index: 20;
  text-align: center;
}

.icon-lang-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.img-lang-dropdownbox {
  width: 20%;  
  margin : 0px 10px;
}

.lang-active-trigger {
  border : 3px solid #C69703;
  border-radius: 10px;
}

.btn-change-lang{
  width: 8rem;
  height: auto;
  align-items: center;  
}

@keyframes colorTransform {
  from {
    color: #231f20;
    border-bottom: 2px solid white;
  }
  to {
    color: #cccaae;
    border-bottom: 2px solid #cccaae;
  }
}

// - navbar portrait mobile 
@media only screen and (min-width: 320px) and (max-width: 374px) and (orientation: portrait) {
  nav ul li a {
    font-size: 0.5rem;  
  }

  .btn-change-lang{
    font-size: 0.5rem;
    width: 3.6rem;
    text-align: center;
  }

  nav #logo {
    width: 4.5rem;
    margin-left: 1.9rem;
    height : 4.2rem;
    max-height: 4.6rem;
  }

  nav .icon-navbar{
    display:flex;
    color : #C5982D;
    margin-left: 20%;
    margin-right: 10%;
  }

  nav ul {
    display: none;
  }

  .navbar #hidden {
    width: 100%;  
    display: flex;
    margin: 0px; 
    flex-direction: column;
    flex-wrap: nowrap;
    height: fit-content;
  }
}

@media only screen and (min-width: 375px) and (max-width: 767px) and (orientation: portrait) {
  nav #logo {
    width: 4.5rem;
    margin-left: 1.9rem;
    height : 4.2rem;
    max-height: 4.6rem;
  }

  nav ul li a {
    font-size: 0.7rem;  
  }

  .btn-change-lang{
    font-size: 0.7rem;
    width: 4.5rem;
    text-align: center;
  }

  nav .icon-navbar{
    display:flex;
    color : #C5982D;
    margin-left: 20%;
    margin-right: 10%;
  }

  nav ul {
    display: none;
  }

  .navbar #hidden {
    width: 100%;  
    display: flex;
    margin: 0px; 
    flex-direction: column;
    flex-wrap: nowrap;
    height: fit-content;
  }
}
// - navbar landscape mobile 

@media only screen and (min-width: 568px) and (max-width: 666px) and (orientation: landscape){
  nav .icon-navbar{
    display:flex;
    color : #C5982D;
    margin-right: 10%;
  }
  nav ul {
    display: none;
  } 
  nav ul li {
    width: 8rem;
  }

  nav ul li a {
    font-size: 0.8rem;  
  }

  .btn-change-lang{
    font-size: 0.8rem;
    width: 5rem;
    text-align: center;
  }

  nav #logo {
    width: 4.5rem;
    margin-left: 1.9rem;
    height : 4.2rem;
    max-height: 4.6rem;
  }

  .navbar #hidden {
    width: 100%;  
    display: flex;
    margin: 0px; 
    flex-direction: column;
    flex-wrap: nowrap;
    height: fit-content;
  }
}
@media only screen and (min-width: 667px) and (max-width: 1025px) and (orientation: landscape){
  nav ul li a {
    font-size: 0.5rem;  
  }

  .btn-change-lang{
    font-size: 0.5rem;
    width: 3.6rem;
    text-align: center;
  }


  nav ul {
    margin-right: 1.8rem;
  }
}

// - navbar portrait tablets/ipad

@media only screen and (min-width: 768px) and (max-width: 1500px) and (orientation: portrait){
  nav .icon-navbar{
    display:flex;
    color : #C5982D;
    margin-right: 10%;
  }

  nav ul {
    display: none;
  } 

  .btn-change-lang{
    text-align: center;
    width: 7.5rem;
  }

  nav #logo {
    width: 4.5rem;
    margin-left: 1.9rem;
    height : 4.2rem;
    max-height: 4.6rem;
  }

  .navbar #hidden {
    width: 100%;  
    display: flex;
    margin: 0px; 
    flex-direction: column;
    flex-wrap: nowrap;
    height: fit-content;
  }

}